import React, { useState, useRef, useEffect } from "react";
import Icon, { SearchOutlined, AppstoreOutlined, LoadingOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { AutoComplete, Input } from "antd";
import IntlMessage from "components/util-components/IntlMessage";
import { navigationConfig } from "configs/NavigationConfig";
import { getManualOptionList, getFixedOptionList, loadingSearchOptionList } from "./helper";
import { search_get } from "api/producers";

function getOptionList(navigationTree, optionTree) {
  optionTree = optionTree ? optionTree : [];
  for (const navItem of navigationTree) {
    if (navItem.submenu.length === 0) {
      optionTree.push(navItem);
    }
    if (navItem.submenu.length > 0) {
      getOptionList(navItem.submenu, optionTree);
    }
  }
  const manualOptionTree = getManualOptionList();

  return [...optionTree, ...manualOptionTree];
}

const optionList = getOptionList(navigationConfig());

const searchResult = (isLoading = false) => {
  const items = [];

  optionList.forEach((item) => {
    items.push({
      value: item.path,
      description: item.description,
      tags: item.tags,
      label: (
        <Link to={item.path}>
          <div className="search-list-item">
            <div className="icon">
              <Icon component={item.icon} style={{ fontSize: "20px", color: "#08c" }} />
            </div>
            <div>
              <div className="font-weight-semibold">
                {item.manual ? item.title : <IntlMessage id={item.title} />}
              </div>
              <div className="font-size-sm text-muted">{item.description || ""} </div>
            </div>
          </div>
        </Link>
      ),
    });
  });

  // items.push(getFixedOptionList());
  //items.push(loadingSearchOptionList());

  return items;
};

const SearchInput = (props) => {
  const { active, close, isMobile, mode, authData } = props;
  const [value, setValue] = useState("");
  const [options, setOptions] = useState([]);
  const [fixedOptions, setFixedOptions] = useState([]);
  const [serverOptions, setServerOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const inputRef = useRef(null);
  let timerTimeout = null;

  const onSelect = (item) => {
    createLocalHistory(item);
    setValue("");
    setOptions([]);
    setFixedOptions([]);
    setServerOptions([]);
    if (close) {
      close();
    }
  };

  useEffect(() => {
    if (active) {
      setOptions(getLocalHistory());
    }

    return () => {
      setValue("");
      setOptions([]);
      setFixedOptions([]);
      setServerOptions([]);
    };
  }, []);

  const createLocalHistory = (path) => {
    const history = JSON.parse(localStorage.getItem("search-history")) || [];
    if (!history?.includes(path)) {
      history.push(path);
    }
    localStorage.setItem("search-history", JSON.stringify(history));
  };

  const getLocalHistory = () => {
    const r = JSON.parse(localStorage.getItem("search-history")) || [];
    return searchResult()?.filter((item) => r.includes(item.value));
  };

  const dbSearchGet = (text) => {
    return new Promise((resolve) => {
      try {
        search_get(authData, text)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const serverSearch = async (searchText) => {
    if (searchText?.length >= 3) {
      setLoading(false);
      return;
    }
    const response = await dbSearchGet(searchText);
    setLoading(true);
    if (response.status) {
      const items = [];
      const products = response.data.products;
      products?.forEach((item) => {
        items.push({
          value: item.path,
          description: item.description,
          title: item.title,
          tags: [],
          label: (
            <Link to={item.path}>
              <div className="search-list-item">
                <div className="icon">
                  <Icon component={AppstoreOutlined} style={{ fontSize: "20px", color: "#c587ff" }} />
                </div>
                <div>
                  <div className="font-weight-semibold">
                    <strong>Produto:</strong> {item.title}
                  </div>
                  <div className="font-size-sm text-muted">{item.description || ""} </div>
                </div>
              </div>
            </Link>
          ),
        });
      });

      setServerOptions(items);
      setLoading(false);
      return;
    }

    setLoading(false);
  };

  const onSearch = (searchText) => {
    setLoading(false);
    if (timerTimeout) {
      clearTimeout(timerTimeout);
    }
    setValue(searchText);
    setOptions(!searchText ? getLocalHistory() : searchResult(true));

    setLoading(true);
    timerTimeout = setTimeout(() => {
      serverSearch(searchText);
    }, 1000);

    // setFixedOptions(!searchText ? [] : getFixedOptionList());
    // setServerOptions(serverSearch(searchText));
    // setTimeout(() => {
    //   setOptions(searchResult(false));
    // }, 2500);
  };

  const allOptions = () => {
    return [...options, ...fixedOptions, ...serverOptions];
  };

  // const autofocus = () => {
  //   inputRef?.current?.focus();
  // };

  // if (active) {
  //   autofocus();
  // }

  return (
    <AutoComplete
      ref={inputRef}
      className={`nav-search-input ${isMobile ? "is-mobile" : ""} ${mode === "light" ? "light" : ""}`}
      dropdownClassName="nav-search-dropdown"
      listHeight={allOptions().length > 5 ? 600 : 200}
      options={allOptions()}
      onSelect={onSelect}
      onSearch={onSearch}
      value={value}
      filterOption={(inputValue, option) => {
        const normalize = (str) =>
          str
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toUpperCase();

        const normalizedInput = normalize(inputValue);
        const tagsJoined = option?.tags?.join(" ") || "";

        return (
          normalize(option?.value || "").indexOf(normalizedInput) !== -1 ||
          normalize(option?.description || "").indexOf(normalizedInput) !== -1 ||
          normalize(option?.title || "").indexOf(normalizedInput) !== -1 ||
          normalize(tagsJoined).indexOf(normalizedInput) !== -1 ||
          option?.isFixed === true
        );
      }}
    >
      <Input
        loading={loading}
        placeholder="Acesso rápido. Escreve o que procuras...."
        prefix={loading ? <LoadingOutlined className="mr-0" /> : <SearchOutlined className="mr-0" />}
      />
    </AutoComplete>
  );
};

export default SearchInput;
