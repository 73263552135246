import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import { connect } from "react-redux";
import AppLayout from "layouts/app-layout";

import Admin2FA from "./admin-2fa";
//import AppWABuddyLayout from "layouts/app-wa-buddy-layout";
//import AppSMSLayout from "layouts/app-sms-layout";
import AuthLayout from "layouts/auth-layout";
import AppLocale from "lang";
import { IntlProvider } from "react-intl";
import { ConfigProvider } from "antd";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import useBodyClass from "hooks/useBodyClass";
import AuthProvider from "../context/Auth";
import Auth0Authentication from "../components/util-components/Auth0";
import SocketIO from "../components/util-components/SocketIO";

//import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

function RouteInterceptor({ children, isAuthenticated, ...rest }) {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: AUTH_PREFIX_PATH,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

const getApp = () => {
  try {
    const host = window.location.hostname || "app.salespark.io";

    if (host === "localhost" || host === "beta-app.salespark.io" || host === "app.salespark.io") {
      return "default";
    }

    if (host === "sms.localhost" || host === "sms-beta.salespark.io" || host === "sms.salespark.io") {
      return "sms";
    }

    if (
      host === "wa-buddy.localhost" ||
      host === "wa-buddy-beta.salespark.io" ||
      host === "wa-buddy.salespark.io"
    ) {
      return "wa-buddy";
    }
    return "default";
  } catch (error) {
    return "default";
  }
};

const Views = (props) => {
  // Initialize ReactQuery
  //https://tanstack.com/query/v4/docs/installation
  // const queryClient = new QueryClient();

  // const rollbar = useRollbar(); // <-- must have parent Provider
  // rollbar.info("Hello world!");
  //

  const { token, location, direction } = props;

  useBodyClass(`dir-${direction}`);

  const locale = "pt";

  const currentAppLocale = AppLocale[locale];

  return (
    <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
      <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
        <Auth0Provider
          domain={process.env.REACT_APP_AUTH0_URL}
          clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
          redirectUri={window.location.origin}
        >
          <AuthProvider>
            <Auth0Authentication>
              <Switch>
                <Route exact path="/">
                  <Redirect to={"/dashboard"} />
                </Route>

                <Route path="/admin-2fa" component={Admin2FA} />

                <Route path={AUTH_PREFIX_PATH}>
                  <AuthLayout direction={direction} />
                </Route>

                {/* {app === "sms" && (
                    <Route path={APP_PREFIX_PATH}>
                      <AppSMSLayout direction={direction} location={location} app="sms" />
                    </Route>
                  )}
                  {app === "sms" && (
                    <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
                      <AppSMSLayout direction={direction} location={location} app="sms" />
                    </RouteInterceptor>
                  )}

                  {app === "wa-buddy" && (
                    <Route path={APP_PREFIX_PATH}>
                      <AppWABuddyLayout direction={direction} location={location} app="wa-buddy" />
                    </Route>
                  )}
                  {app === "wa-buddy" && (
                    <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
                      <AppWABuddyLayout direction={direction} location={location} app="wa-buddy" />
                    </RouteInterceptor>
                  )} */}

                <Route path={APP_PREFIX_PATH}>
                  <AppLayout direction={direction} location={location} />
                </Route>

                <RouteInterceptor path={APP_PREFIX_PATH} isAuthenticated={token}>
                  <AppLayout direction={direction} location={location} />
                </RouteInterceptor>
              </Switch>
              <SocketIO />
            </Auth0Authentication>
          </AuthProvider>
        </Auth0Provider>
      </ConfigProvider>
    </IntlProvider>
  );
};

const mapStateToProps = ({ theme, auth }) => {
  const { direction } = theme;
  const { token } = auth;
  return { token, direction };
};

export default withRouter(connect(mapStateToProps)(Views));
