import axios from "axios";

const api_request = (auth_data) => {
  return new axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: process.env.REACT_APP_ENVIRONMENT.toLowerCase() === "development" ? 300000 : 15000,
    headers: {
      "Content-Type": "application/json",
      "x-email": auth_data.email,
      "x-guserid": auth_data.guserid,
      "x-token": auth_data.token,
    },
  });
};
const checkAuth = (resp) => {
  //TODO: #6:
  //Impement on components on catch: If status is 401 execute Google Signout
  try {
    if (resp.response.status === 401) window.location.reload();
  } catch (error) {}
};

export const app_agent_post = async (auth_data, payload) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .post("/ai/app-agent", payload)
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};

export const app_agent_info_get = async (auth_data) => {
  return new Promise((resolve, reject) => {
    api_request(auth_data)
      .get("/ai/app-agent/info")
      .then((res) => resolve(res))
      .catch((err) => {
        checkAuth(err);
        reject({ ...err });
      });
  });
};
