import { Modal } from "antd";
import { SettingOutlined, RobotOutlined, LoadingOutlined, UserOutlined } from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

export const loadingSearchOptionList = () => {
  return [
    {
      isFixed: true,
      isLoading: true,
      disabled: true,
      value: "",
      description: "",
      tags: [],
      label: (
        <div style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} className="m-3">
          <div className="mr-3">
            <LoadingOutlined style={{ color: "#EE6B4C" }} />
          </div>

          <div className="font-weight-semibold ">A procurar mais resultados...</div>
        </div>
      ),
    },
  ];
};

export const getManualOptionList = () => {
  return [
    {
      manual: true,
      title: "A minha conta > Plano & Opções",
      description: "Plano e Opções de Conta (Plano e Notificações).",
      icon: UserOutlined,
      path: `${APP_PREFIX_PATH}/account/plan`,
      tags: ["conta", "minha conta", "plano", "notificação", "configurações", "configuração"],
    },
    {
      manual: true,
      title: "A minha conta > Dados Pessoais",
      description: "Dados Pessoais e Chave API.",
      icon: UserOutlined,
      path: `${APP_PREFIX_PATH}/account/personal-data`,
      tags: ["conta", "minha conta", "dados", "pessoais", "api"],
    },
    {
      manual: true,
      title: "A minha conta > Perfil de Produtor",
      description: "Título profissional, áreas de atuação e biografia",
      icon: UserOutlined,
      path: `${APP_PREFIX_PATH}/account/profile-data`,
      tags: ["conta", "minha conta", "perfil", "produtor", "biografia", "áreas", "atuação"],
    },
    {
      manual: true,
      title: "A minha conta > Dados do Negócio/Empresa",
      description: "Dados do Negócio/Empresa (Forma Jurídica, Número de Contribuinte, Comprovativos, etc)",
      icon: UserOutlined,
      path: `${APP_PREFIX_PATH}/account/company-data`,
      tags: [
        "conta",
        "minha conta",
        "dados",
        "negócio",
        "empresa",
        "jurídica",
        "contribuinte",
        "comprovativo",
        "morada",
        "nif",
      ],
    },
    {
      manual: true,
      title: "A minha conta > Dados Financeiros (Contas Bancárias)",
      description: "Gestão da(s) conta(s) bancária(s) para recebimento de fundos.",
      icon: UserOutlined,
      path: `${APP_PREFIX_PATH}/account/financial-data`,
      tags: [
        "conta",
        "minha conta",
        "financeiro",
        "conta",
        "banco",
        "iban",
        "bancária",
        "recebimento",
        "fundo",
        "pagamento",
        "dinheiro",
      ],
    },
    {
      manual: true,
      title: "A minha conta > Saldo & Pagamentos",
      description: "Pedido de levantamento de fundos e histórico de pagamentos.",
      icon: UserOutlined,
      path: `${APP_PREFIX_PATH}/account/payouts`,
      tags: [
        "conta",
        "minha conta",
        "saldo",
        "pagamento",
        "levantamento",
        "fundos",
        "dinheiro",
        "sacar",
        "pagar",
        "saque",
      ],
    },
    ///////////////////////////////////////////////////////////////////////////
    {
      manual: true,
      title: "Pixel Meta (Facebook)",
      description: "Monitoriza o comportamento dos utilizadores com o Pixel Meta.",
      icon: SettingOutlined,
      path: `${APP_PREFIX_PATH}/work-tools/meta-pixel`,
      tags: ["meta", "pixel", "facebook", "ads", "marketing", "integração"],
    },
    {
      manual: true,
      title: "Pixel Google Analytics 4 (GA4)",
      description: "Monitoriza o comportamento dos utilizadores com o Pixel Google Analytics 4.",
      icon: SettingOutlined,
      path: `${APP_PREFIX_PATH}/work-tools/ga4-pixel`,
      tags: ["ga4", "google", "analytics", "marketing", "integração"],
    },
    {
      manual: true,
      title: "Webhooks",
      description: "Configura as integrações externas e as notificações com a API da SalesPark.",
      icon: SettingOutlined,
      path: `${APP_PREFIX_PATH}/work-tools/webhooks`,
      tags: [
        "webhooks",
        "api",
        "zapier",
        "make",
        "pabbly",
        "integração",
        "notificações",
        "automate.io",
        "integromat",
        "integrately",
      ],
    },
    {
      manual: true,
      title: "MailerLite",
      description: "Automatiza o e-mail marketing com o MailerLite",
      icon: SettingOutlined,
      path: `${APP_PREFIX_PATH}/work-tools/mailerlite`,
      tags: ["mailerlite", "e-mail", "marketing", "integração", "email"],
    },
    {
      manual: true,
      title: "Systeme.io",
      description: "Automatiza o e-mail marketing com o Systeme.io.",
      icon: SettingOutlined,
      path: `${APP_PREFIX_PATH}/work-tools/systeme-io`,
      tags: ["systeme", "e-mail", "marketing", "integração", "email"],
    },
    {
      manual: true,
      title: "InvoiceXpress",
      description: "Automatiza a emissão de faturas em produtos de gestão externa com o InvoiceXpress.",
      icon: SettingOutlined,
      path: `${APP_PREFIX_PATH}/work-tools/invoicexpress`,
      tags: [
        "invoicexpress",
        "invoice express",
        "fatura",
        "contabilidade",
        "gestão",
        "faturação",
        "recibo",
        "integração",
      ],
    },
    {
      manual: true,
      title: "Moloni",
      description: "Automatiza a emissão de faturas em produtos de gestão externa com o Moloni.",
      icon: SettingOutlined,
      path: `${APP_PREFIX_PATH}/work-tools/moloni`,
      tags: ["Moloni", "molini", "fatura", "contabilidade", "gestão", "faturação", "recibo", "integração"],
    },
    {
      manual: true,
      title: "Bill.pt",
      description: "Automatiza a emissão de faturas em produtos de gestão externa com o Bill.pt.",
      icon: SettingOutlined,
      path: `${APP_PREFIX_PATH}/work-tools/bill`,
      tags: ["bill", "fatura", "contabilidade", "gestão", "faturação", "recibo", "integração"],
    },
    {
      manual: true,
      title: "URL's Curtos",
      description: "Cria e gere URLs curtos para partilhar nas redes sociais e campanhas de marketing.",
      icon: SettingOutlined,
      path: `${APP_PREFIX_PATH}/work-tools/shorteners`,
      tags: ["shortener", "url", "encurtador", "link", "curto", "encurtar"],
    },
  ];
};

export const getFixedOptionList = () => {
  return {
    isFixed: true,
    value: "",
    description: "",
    tags: [],
    label: (
      <div
        style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}
        className="m-3"
        onClick={() => {
          Modal.info({
            title: "Falar com a IA",
            content: "A funcionalidade de falar com a IA ainda não está disponível (mas brevemente).",
            onOk() {},
          });
        }}
      >
        <div className="mr-3">
          <RobotOutlined style={{ color: "#EE6B4C" }} />
        </div>

        <div className="font-weight-semibold ">Falar com a IA (brevemente)</div>
      </div>
    ),
  };
};

export const loadingMessages = [
  "A aquecer os neurónios artificiais... 🧠",
  "A dar comida aos hamsters que fazem os cálculos... 🐹",
  "A consultar a bola de cristal digital... 🔮",
  "A fazer café para o algoritmo... ☕",
  "A dar uma volta à Matrix... 💊",
  "A acordar o assistente virtual da sesta... 😴",
  "A calibrar os sensores de inteligência... 🔧",
  "A carregar as bibliotecas de sabedoria... 📚",
  "A afinar as ondas cerebrais digitais... 🌊",
  "A ligar o modo altamente inteligente... 🦸",
  "A consultar o oráculo dos zeros e uns... 🎲",
  "A pôr os bytes em fila indiana... 🚶",
  "A dar power aos processadores... 🚀",
  "A montar o puzzle digital... 🧩",
  "A fazer cálculos quânticos... ⚛️",
  "A dar uma espreitadela no manual de instruções... 📖",
  "A recarregar as pilhas da criatividade... 🔋",
  "A juntar pensamentos alucinados... 💭",
  "A fazer reunião com os outros bots... 🤖",
  "À procura da resposta no fim do universo... 🌌",
  "A fazer uma pausa para o chá das cinco... 🍵",
  "A fazer contas à vida... 🧮",
  "A dar uma vista de olhos no futuro... 🔮",
  "A fazer uma viagem ao centro da Terra... 🌍",
  "A fazer uma pausa para o café... ☕",
];
