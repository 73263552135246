import React, { useState, useEffect } from "react";
import { Card, Modal, Spin, Typography, notification, Input, Button, Form } from "antd";
import OtpInput from "react-otp-input";
import { useHistory } from "react-router-dom";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { producer_2fa_request, producer_2fa_confirm } from "api/producers";
import { MobileOutlined } from "@ant-design/icons";
import { useAuth } from "context/Auth";

const { Title } = Typography;

const env = process.env.REACT_APP_ENVIRONMENT.toLowerCase() || "development";

const renderTime = ({ remainingTime }) => {
  if (remainingTime === 0) {
    return <div className="timer">Oops...</div>;
  }

  return (
    <div className="timer">
      <div className="text">Faltam</div>
      <div className="value">{remainingTime}</div>
      <div className="text">segundos</div>
    </div>
  );
};

const Admin2FA = () => {
  const [form] = Form.useForm();
  const { auth, setAuth } = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [phoneValidatonCode, setPhoneValidatonCode] = useState("");
  const [phoneValidationModal, setPhoneValidationModal] = useState({ visible: false, loading: false, phone: null });

  useEffect(() => {
    if (auth.data?.admin_token) {
      // cookies.remove(`${env}-salespark-admin-token`);
    }
  }, []);

  const dbAdmin2FARequest = (payload) => {
    return new Promise((resolve) => {
      try {
        producer_2fa_request(auth.data, payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const dbAdmin2FAConfirm = (payload) => {
    return new Promise((resolve) => {
      try {
        producer_2fa_confirm(auth.data, payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (error) {
        resolve({ status: false, data: error });
      }
    });
  };

  const onFinish = async (values) => {
    setIsLoading(true);

    const requestResponse = await dbAdmin2FARequest({ phone: values.phone });
    if (!requestResponse.status) {
      setIsLoading(false);
      notification.error({
        message: "Oops!",
        description: "Ocorreu um erro ao iniciar a autenticação 2FA!",
      });
      return;
    }

    setIsLoading(false);
    setPhoneValidationModal({ visible: true, loading: false, phone: values.phone });
  };

  const validate2FAHandler = async (code) => {
    setIsLoading(true);
    setPhoneValidationModal({ ...phoneValidationModal, loading: false });

    const confirmResponse = await dbAdmin2FAConfirm({ code, phone: phoneValidationModal.phone });
    if (!confirmResponse.status) {
      setIsLoading(false);
      notification.error({
        message: "Oops!",
        description: "Autenticação falhou.",
      });
      return;
    }

    notification.success({
      message: "Sucesso!",
      description: "Autenticação bem sucedida.",
    });

    setPhoneValidationModal({ visible: false, loading: false, phone: null });
    setAuth({ ...auth, data: { ...auth.data, admin_token: confirmResponse.data.token } });

    // var d = new Date();
    // d.setTime(d.getTime() + 12 * 60 * 60 * 1000);

    localStorage.setItem(`${env}-salespark-admin-token`, confirmResponse.data.token);

    setTimeout(() => {
      setIsLoading(false);
      setPhoneValidatonCode("");
      history.push("/admin/dashboard");
    }, 1000);
  };

  return (
    <>
      <Spin spinning={isLoading}>
        <div className="my-5 container staff-2fa-container">
          <div className="text-center">
            <img src="/img/logo.png" alt="SalesPark" />
          </div>
          <Card>
            <div className="text-center mb-2">
              <h3 className="text-muted">Backoffice da SalesPark</h3>

              <h4 className="text-muted">Autenticação 2FA</h4>
            </div>
            {isLoading === false && (
              <Form form={form} layout="vertical" className="mt-4 ml-5 mr-5" onFinish={onFinish}>
                <Form.Item
                  name="phone"
                  // label="Número de telefone"
                  className="phone-input-2fa"
                  rules={[{ required: true, message: "É necessário indicar o número de telemóvel..." }]}
                >
                  <Input placeholder="N. telemóvel" disabled={isLoading} />
                </Form.Item>
                <Form.Item>
                  <div style={{ textAlign: "center" }}>
                    <Button
                      shape="round"
                      size="large"
                      type="primary"
                      htmlType="submit"
                      disabled={isLoading}
                      loading={isLoading}
                    >
                      <MobileOutlined /> Confirmar número de telefone
                    </Button>
                  </div>
                </Form.Item>
              </Form>
            )}
          </Card>
        </div>
      </Spin>
      <Modal
        closable={false}
        width={500}
        destroyOnClose
        title="Autenticação 2FA"
        okText="Confirmar"
        cancelText="Cancelar"
        visible={phoneValidationModal.visible}
        onCancel={() => setPhoneValidationModal({ visible: false, loading: false, phone: "" })}
        onOk={() => validate2FAHandler(phoneValidatonCode)}
      >
        {phoneValidationModal.loading === true && (
          <div className="phoneOtpWaitContainer">
            <Spin spinning={phoneValidationModal.loading} size="large" />
          </div>
        )}
        {phoneValidationModal.loading === false && (
          <div className="phoneOtpContainer">
            <div className="phoneOtpContainerTitle">
              <Title level={4}>Introduz o código enviado para o teu telemóvel</Title>
            </div>
            <div className="phoneOtpContainerInput">
              <OtpInput
                shouldAutoFocus
                className="phoneOtp"
                numInputs={6}
                value={phoneValidatonCode}
                onChange={(e) => {
                  setPhoneValidatonCode(e);
                  if (e.length === 6) {
                    validate2FAHandler(e);
                  }
                }}
              />
            </div>
            <div className="phoneOtpContainerCountdown">
              <CountdownCircleTimer
                isPlaying
                size={150}
                strokeWidth={8}
                colors={["#59cf15", "#cfc615", "#cfa115", "#cf2e15"]}
                duration={120}
                colorsTime={[120, 60, 20, 0]}
                onComplete={() => {
                  setPhoneValidationModal({ visible: false, phone: null, loading: false });
                  setPhoneValidatonCode("");

                  notification.error({
                    message: "Oops!",
                    description: "Autenticação falhou.",
                  });
                }}
              >
                {renderTime}
              </CountdownCircleTimer>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default Admin2FA;
