import React, { useState } from "react";
import { connect } from "react-redux";
import utils from "utils";
import SearchInput from "./SearchInput";
import AIButton from "components/util-components/AI-Button";
import AIDrawer from "./AIDrawer";
import { useAuth } from "../../../context/Auth";

export const NavSearch = (props) => {
  const { auth } = useAuth();
  const { active, close, headerNavColor } = props;
  const [AIDrawerVisible, setAIDrawerVisible] = useState(false);
  const mode = utils.getColorContrast(headerNavColor);

  const experimentalAI = auth?.data?.scope?.includes("experimental-ai") === true;

  return (
    // <div
    //   className={`nav-search ${active ? "nav-search-active" : ""} ${mode}`}
    //   style={{ backgroundColor: headerNavColor }}
    // >
    <div className="w-100" style={{ display: "inline-flex" }}>
      <div className="w-100 ml-4 mr-1">
        <SearchInput close={close} active={active} authData={auth.data} />
      </div>
      {experimentalAI && (
        <>
          <div className="ml-1 mr-4">
            <AIButton
              shape="circle"
              size="small"
              // style={{ minWidth: 250 }}

              showLoading={false}
              onClickButton={() => setAIDrawerVisible({ visible: true })}
            />
          </div>
          <AIDrawer auth={auth} close={() => setAIDrawerVisible(false)} visible={AIDrawerVisible.visible} />
        </>
      )}
    </div>
    // <div className="nav-close" onClick={close}>
    //   <CloseOutlined />
    // </div>
    // </div>*/}
  );
};

const mapStateToProps = ({ theme }) => {
  const { headerNavColor } = theme;
  return { headerNavColor };
};

export default connect(mapStateToProps, {})(NavSearch);
