const urls = [
  {
    pattern: /\/dashboard/g,
    showVideo: false,
    // videoId: "https://www.youtube.com/embed/pXcvexA6EgI",
    // title: "Dashboard",
  },

  {
    pattern: /\/reports\/documents/g,
    videoId: "https://www.youtube.com/embed/VviZeVTCqxI",
    kbUrl: "https://ajuda.salespark.io/help/documentos-fiscais?version=latest",
    title: "Documentos Fiscais",
  },

  {
    pattern: /\/products\/list/g,
    videoId: "https://www.youtube.com/embed/GDrw-M5tDsU",
    // kbUrl: "https://ajuda.salespark.io/help/documentos-fiscais?version=latest",
    title: "Lista de Produtos",
  },

  //
  // {
  //   pattern: /\/products/g,
  //   videoId: "1",
  //   title: "Produtos",
  // },
];

function findPathInUrls(path, urls) {
  return urls.find((url) => new RegExp(url?.pattern).test(path));
}

const getVideo = (path) => {
  if (!path) return undefined;

  const matchingUrl = findPathInUrls(path, urls);

  if (matchingUrl !== undefined) {
    if (matchingUrl.showVideo === false) {
      return undefined;
    }

    return matchingUrl;
  }

  return {
    videoId: "463ed9367db14296a2a8767af7036ee6",
    title: "Brevemente",
  };
};

export default getVideo;
