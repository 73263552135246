import React, { useState, useEffect, useRef } from "react";
import { Modal, Tooltip, Input, Avatar, message } from "antd";
import { SendOutlined, LikeOutlined, DislikeOutlined } from "@ant-design/icons";
import { app_agent_post, app_agent_info_get } from "../../../api/ai/index";
import AIButton from "components/util-components/AI-Button";
import { loadingMessages } from "./helper";
import moment from "moment-timezone";

const { Search } = Input;

const delay = (ms) => new Promise((res) => setTimeout(res, ms));
const AIDrawer = ({ close, visible, auth }) => {
  const aiContainer = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [messages, setMessages] = useState([]);
  const [loadingMessage, setLoadingMessage] = useState(null);
  const [userPrompt, setUserPrompt] = useState("");
  const [started, setStarted] = useState(false);

  let loadingMessageTimer = null;

  useEffect(() => {
    randomWaitingMessage();
  }, []);

  useEffect(() => {
    if (visible === true) startAI();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const startAI = async () => {
    try {
      if (started === true) return;

      const _messages = messages || [];

      _messages.push({
        id: "waiting",
        date: new Date(),
        user: "system",
        text: "A iniciar a Nova AI...",
        fun: false,
        type: "loading",
      });
      setMessages([..._messages]);

      const response = await dbAppAgentInfoGet();

      // Replace waiting-"id" with newAIresponse
      const _messagesFinal = messages.filter((msg) => msg.type !== "loading");

      if (!response.status) {
        _messagesFinal.push({
          id: "initial-message",
          date: new Date(),
          user: "system",
          text: "😱 Parece que a Nova AI está offline. Tenta novamente mais tarde ou entra em contacto com o suporte.",
          type: "warning",
        });
        setMessages([..._messagesFinal]);
        setTimeout(() => scrollToBottom(), 150);
        return;
      }

      const { status, data } = response.data;

      _messagesFinal.push(data);
      setMessages(_messagesFinal);
      setStarted(true);
      setTimeout(() => scrollToBottom(), 150);

      // Error handling
    } catch (err) {
      // console.log("Error starting AI...", err);
    }
  };

  const scrollToBottom = () => {
    // aiContainer.current?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    if (aiContainer.current) {
      aiContainer.current.scrollTop = aiContainer.current.scrollHeight;
      // aiContainer.current.scrollIntoView({
      //   behavior: "smooth",
      //   block: "end",
      //   inline: "nearest",
      // });
    }
  };

  const parseUser = (user) => {
    if (user === "producer") {
      return {
        name: auth.data.name,
        email: auth.data.email,
        picture: <Avatar src={auth.data.picture} size={40} />,
      };
    }

    if (user === "system") {
      return {
        name: "SalesPark",
        email: "suporte@salespark.io",
        picture: (
          <Avatar
            src="https://salespark.fra1.cdn.digitaloceanspaces.com/producers/profiles/dev--xbf35solclujm08"
            size={40}
          />
        ),
      };
    }

    return {
      name: "Nova AI",
      email: "suporte@salespark.io",
      picture: (
        <Avatar className="btn-ai" style={{ lineHeight: "33px" }}>
          <span className="ai-stars-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
              <path d="M7.657 6.247c.11-.33.576-.33.686 0l.645 1.937a2.89 2.89 0 0 0 1.829 1.828l1.936.645c.33.11.33.576 0 .686l-1.937.645a2.89 2.89 0 0 0-1.828 1.829l-.645 1.936a.361.361 0 0 1-.686 0l-.645-1.937a2.89 2.89 0 0 0-1.828-1.828l-1.937-.645a.361.361 0 0 1 0-.686l1.937-.645a2.89 2.89 0 0 0 1.828-1.828zM3.794 1.148a.217.217 0 0 1 .412 0l.387 1.162c.173.518.579.924 1.097 1.097l1.162.387a.217.217 0 0 1 0 .412l-1.162.387A1.73 1.73 0 0 0 4.593 5.69l-.387 1.162a.217.217 0 0 1-.412 0L3.407 5.69A1.73 1.73 0 0 0 2.31 4.593l-1.162-.387a.217.217 0 0 1 0-.412l1.162-.387A1.73 1.73 0 0 0 3.407 2.31zM10.863.099a.145.145 0 0 1 .274 0l.258.774c.115.346.386.617.732.732l.774.258a.145.145 0 0 1 0 .274l-.774.258a1.16 1.16 0 0 0-.732.732l-.258.774a.145.145 0 0 1-.274 0l-.258-.774a1.16 1.16 0 0 0-.732-.732L9.1 2.137a.145.145 0 0 1 0-.274l.774-.258c.346-.115.617-.386.732-.732z" />
            </svg>
          </span>
        </Avatar>
      ),
    };
  };

  const queryAI = async (query) => {
    if (isLoading) return;
    if (query.trim() === "") {
      message.warning("É necessário escrever uma pergunta...");
      return;
    }
    setIsLoading(true);

    const IdBasedInDate = new Date().getTime();

    // Init random loading message
    loadingMessageTimer = setInterval(() => randomWaitingMessage(), 5000);

    const actualMessages = messages;
    actualMessages.push({
      id: `producer-message-${IdBasedInDate}`,
      date: new Date(),
      user: "producer",
      text: query,
      type: "success",
    });

    actualMessages.push({
      id: `waiting-${IdBasedInDate}`,
      date: new Date(),
      user: "bot",
      text: "A processar a tua pergunta...",
      type: "loading",
    });

    setMessages(actualMessages);
    setUserPrompt("");
    setTimeout(() => scrollToBottom(), 250);

    const response = await dbAppAgentPost({ query });
    clearInterval(loadingMessageTimer);
    if (!response.status) {
      const newAIresponse = {
        date: new Date(),
        user: "bot",
        text: "Ocorreu um erro ao processar a tua pergunta. Tenta novamente mais tarde.",
        type: "error",
      };

      // Replace waiting-"id" with newAIresponse
      const _messages = messages.filter((msg) => msg.id !== `waiting-${IdBasedInDate}`);
      _messages.push(newAIresponse);

      setMessages([..._messages]);
      setIsLoading(false);
      return;
    }

    const { answer, feedback } = response.data;
    const _anwser = answer ? answer : "Não encontrei uma resposta para a tua pergunta...";
    const _messages = messages.filter((msg) => msg.id !== `waiting-${IdBasedInDate}`);
    _messages.push({
      id: `bot-message-${IdBasedInDate}`,
      date: new Date(),
      user: "bot",
      text: _anwser,
      feedback: feedback,
      type: "success",
    });

    setMessages(_messages);
    setIsLoading(false);
  };

  const dbAppAgentPost = async (payload) => {
    return new Promise((resolve) => {
      try {
        app_agent_post(auth.data, payload)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (err) {
        resolve({ status: false, data: err });
      }
    });
  };

  const dbAppAgentInfoGet = async () => {
    return new Promise((resolve) => {
      try {
        app_agent_info_get(auth.data)
          .then((res) => resolve({ status: true, data: res.data }))
          .catch((err) => resolve({ status: false, data: err }));

        // Error handling
      } catch (error) {
        resolve({ status: false, data: error });
        //  console.log("Error getting AI info...", error);
      }
    });
  };

  const randomWaitingMessage = () => {
    const randomMessage = loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
    setLoadingMessage(randomMessage);
  };

  const parseMarkdown = (text) => {
    return text.replace(/(\*\*|__)(.*?)(\*\*|__)/gm, "<strong>$2</strong>").replace(/\n/gm, "<br />");
  };

  const parseStringToMultiline = (string) => {
    return <span dangerouslySetInnerHTML={{ __html: parseMarkdown(string) }} />;
  };

  const HandleMessage = ({ message, loadingMessage }) => {
    if (message.type === "loading" && message.fun !== false) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="bouncing-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="ml-2">{loadingMessage}</div>
        </div>
      );
    }
    if (message.type === "loading" && message.fun === false) {
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <div className="bouncing-dots">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
          </div>
          <div className="ml-2">{message.text}</div>
        </div>
      );
    }

    if (message.user === "bot") {
      return <div>{parseStringToMultiline(message.text)}</div>;
    }

    return <div>{message.text}</div>;
  };

  return (
    <Modal
      title="SalesPark IA"
      className="msger-chat-modal"
      visible={visible}
      style={{ top: "20px" }}
      closable={true}
      width={1100}
      footer={null}
      onCancel={close}
    >
      <div className="ai-chat-container" ref={aiContainer}>
        <section className="msger">
          <main className="msger-chat">
            {messages.map((message, index) => {
              const user = parseUser(message.user);
              return (
                <div
                  className={`msg ${
                    message.user === "bot" || message.user === "system" ? "left-msg" : "right-msg"
                  }`}
                  key={index}
                >
                  <div style={{ minWidth: "40px" }}>{user.picture}</div>

                  <div className="msg-bubble">
                    <div className="msg-info">
                      <div className="msg-info-name">{user.name}</div>
                      <div className="msg-info-time text-muted">
                        <small>{moment(message.date).format("HH:mm")}</small>
                      </div>
                    </div>

                    <div className="msg-text">
                      <HandleMessage message={message} loadingMessage={loadingMessage} />
                    </div>
                    {message.user === "bot" && message.feedback === true && (
                      <div className="msg-feedback text-muted">
                        <div>
                          <span>A minha resposta foi útil e está correta?</span>
                        </div>
                        <div className="ml-2">
                          <Tooltip title="Sim, a resposta foi útil">
                            <LikeOutlined className="clickable_hover" />
                          </Tooltip>
                        </div>
                        <div className="ml-2">
                          <Tooltip title="Não, a resposta não foi útil ou está incorreta">
                            <DislikeOutlined className="clickable_hover" />
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
          </main>

          {/* </form> */}
        </section>
      </div>
      <div className="p-4" style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        {/* <Search
          className="btn-ai-button"
          size="large"
          placeholder="Escreve aqui a tua pergunta..."
          value={userPrompt}
          onChange={(e) => setUserPrompt(e.target.value)}
          onSearch={(value) => queryAI(value)}
          enterButton={<SendOutlined />}
        /> */}
        <Input
          size="large"
          disabled={isLoading || !started}
          placeholder="Escreve aqui a tua pergunta..."
          value={userPrompt}
          onChange={(e) => setUserPrompt(e.target.value)}
          className="mr-2"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              queryAI(userPrompt);
            }
          }}
          // onSearch={(value) => queryAI(value)}
          // enterButton={<SendOutlined />}
        />
        <AIButton
          size="large"
          onClickButton={() => queryAI(userPrompt)}
          loading={isLoading}
          disabled={!started}
          showLoading={true}
          style={{ width: 120 }}
          icon={<SendOutlined style={{ fontSize: 25 }} />}
        />
      </div>
    </Modal>
  );
};

export default AIDrawer;
